import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Bill Gates & Bitcoins",
  "date": "2014-12-20T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Finally the business legend do care about the digital revolution started by Bitcoin`}</p>
    <p>{`At the event, when asked about Bitcoin’s potential to ease the cost of payment transactions for moving money from one place to another Gates waxed mostly positively about the virtual money.`}</p>
    <p>{`“Bitcoin is exciting because it shows how cheap it can be,” he told Erik Schatzker during a Bloomberg TV’s Smart Street show interview . “Bitcoin is better than currency in that you don’t have to be physically in the same place and, of course, for large transactions, currency can get pretty inconvenient.”
Gates again reiterated his stance on cryptocurrencies when he delivered the event’s closing keynote address, in which he stated that, in the future, financial transactions will eventually “be digital, universal and almost free.”
“The customers we’re talking about aren’t trying to be anonymous,”
“They’re willing to be known, so Bitcoin technology is key and you can add to it or you could build a similar technology where there’s enough attribution where people feel comfortable that this is nothing to do with terrorism or any type of money laundering"
The last time Gates publicly commented on Bitcoin was last February, the day Bitcoin currency conversions debuted on Microsoft’s Bing search engine, when he skirted questions about Microsoft’s stance on the cryptocurrency and subsequent others during a lively Reddit Ask Me Anything (AMA) session.
Instead of answering Bitcoin-related queries head on, Gates shifted the focus to the Bill and Melinda Gates Foundation-backed Vodaphone M-pesa, a mobile phone-based money transfer and microfinancing service in Kenya. Gates said that his organization is “involved in digital money, but unlike Bitcoin it would not be anonymous digital money.” He went on to predict that “digital money will catch on in India and parts of Africa and help the poorest a lot” over the next five years.
Now that Gates is officially Microsoft’s technical advisor, perhaps he’ll make a push for Microsoft to accept Bitcoin (or another virtual money) as a form of payment and/or weave cryptocurrency into the company’s nascent “Zero-Effort” mobile payments initiative. Only time will tell.
Bitcoin is started to gain attention from peoples , no one can predict what future holds on .
May be fiat currency will become a nightmare in coming ye`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      